<template>
  <div
    v-if="
      curatorSettings &&
      mainStore.currentSite &&
      mainStore.currentSite.settings.curator
    "
    class="bg-[#020202] md:px-16"
  >
    <div class="mx-auto max-w-[2560px] py-6 px-4">
      <div :id="curatorSettings.id" />
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";

import { useMainStore } from "@/store/index";

const curatorFeeds = {
  // Global
  default: {
    id: "curator-feed-default-feed-layout",
    src: "https://cdn.curator.io/published/c7ff9088-401a-40cd-bc52-12659b8d411e.js",
  },
  // Argentina
  ar: {
    id: "curator-feed-benelli-argentina-layout",
    src: "https://cdn.curator.io/published/913d29e2-92bd-421d-89d4-b2835ca641ac.js",
  },
  // Australia
  au: {
    id: "curator-feed-benelli-australia-layout",
    src: "https://cdn.curator.io/published/35bcce74-6a2d-4cdb-b20d-3197cb4d8c6f.js",
  },
  // Bolivia
  bo: {
    id: "curator-feed-benelli-bolivia-layout",
    src: "https://cdn.curator.io/published/ad0a52b6-95dd-4827-8ce8-a27df04f4f1f.js",
  },
  // Cambodia
  kh: {
    id: "curator-feed-benelli-cambodia-layout",
    src: "https://cdn.curator.io/published/8df87425-0205-4bce-9026-8c8e72f43828.js",
  },
  // Chile
  cl: {
    id: "curator-feed-benelli-chile-layout",
    src: "https://cdn.curator.io/published/f4d367ec-b1fb-40e8-86a3-6aca4ce9fd9a.js",
  },
  // Colombia
  co: {
    id: "curator-feed-benelli-colombia-layout",
    src: "https://cdn.curator.io/published/e78da06e-4443-416a-9f2b-f3f03f6e1fe4.js",
  },
  // Equator
  ec: {
    id: "curator-feed-benelli-ecuador-layout",
    src: "https://cdn.curator.io/published/060ad0dd-021c-444e-885d-a02bb1dd134e.js",
  },
  // El Salvador
  sv: {
    id: "curator-feed-benelli-el-salvador-layout",
    src: "https://cdn.curator.io/published/44afa7a9-23f8-427f-af62-bf154a982b08.js",
  },
  // Spain
  es: {
    id: "curator-feed-benelli-espana-layout",
    src: "https://cdn.curator.io/published/2555bb8a-ee90-420a-b3ff-0e2b999cdf10.js",
  },
  // Germany
  // de: {
  //   id: 'curator-feed-benelli-germany-layout',
  //   src:
  //     'https://cdn.curator.io/published/928e783d-0a6a-4d38-97b7-ed818f4d6d5f.js'
  // },
  // Egypt
  eg: {
    id: "curator-feed-benelli-egypt-layout",
    src: "https://cdn.curator.io/published/47c693bb-69f5-4751-a419-9d54871b8f01.js",
  },
  // Guatemala
  gt: {
    id: "curator-feed-benelli-guatemala-layout",
    src: "https://cdn.curator.io/published/caca0f48-20c7-44b9-bb07-c5cb535f0b30.js",
  },
  // Hungary
  hu: {
    id: "curator-feed-benelli-hungary-layout",
    src: "https://cdn.curator.io/published/90239b5d-6da0-431e-ba38-6254006b5acc.js",
  },
  // Iran
  ir: {
    id: "curator-feed-benelli-iran-layout",
    src: "https://cdn.curator.io/published/4703fda2-9b56-4237-9385-63d6bafeb2cb.js",
  },
  // Italy
  it: {
    id: "curator-feed-benelli-italy-layout",
    src: "https://cdn.curator.io/published/ab69fb4b-25d5-4137-98be-467f056d334f.js",
  },
  // Malaysia
  my: {
    id: "curator-feed-benelli-malaysia-layout",
    src: "https://cdn.curator.io/published/80b913ec-28e3-445a-995b-571415fb4b08.js",
  },
  // Mexico
  mx: {
    id: "curator-feed-benelli-mexico-layout",
    src: "https://cdn.curator.io/published/c49d885e-fab4-41e6-bc9d-cb77be88aba4.js",
  },
  // Morocco
  ma: {
    id: "curator-feed-benelli-morocco-layout",
    src: "https://cdn.curator.io/published/49b8aabe-3821-4303-9577-d76b5db3ec07.js",
  },
  // Nepal
  np: {
    id: "curator-feed-benelli-nepal-layout",
    src: "https://cdn.curator.io/published/e142f774-331a-48a1-9c8e-914f2ca8955f.js",
  },
  // Netherlands
  nl: {
    id: "curator-feed-benelli-netherlands-layout",
    src: "https://cdn.curator.io/published/1a70eb25-369a-4e16-ac10-ca1ffe855f0f.js",
  },
  // New Zealand
  nz: {
    id: "curator-feed-benelli-new-zealand-layout",
    src: "https://cdn.curator.io/published/d8532218-64d7-4699-95c1-e7be9481cf32.js",
  },
  // Panama
  pa: {
    id: "curator-feed-benelli-panama-layout",
    src: "https://cdn.curator.io/published/053f4a42-9013-494e-89a5-0083d3398311.js",
  },
  // Pakistan
  pk: {
    id: "curator-feed-benelli-pakistan-layout",
    src: "https://cdn.curator.io/published/4a62ef2e-3462-43f6-81a5-03547cf6da61.js",
  },
  // Paraguay
  py: {
    id: "curator-feed-benelli-paraguay-layout",
    src: "https://cdn.curator.io/published/36a9bf92-ccbb-4e00-bc23-8501b9628734.js",
  },
  // Peru
  pe: {
    id: "curator-feed-benelli-peru-layout",
    src: "https://cdn.curator.io/published/d7e3584c-a4fe-4d82-befd-6e9f291c9947.js",
  },
  // Philippines
  ph: {
    id: "curator-feed-benelli-philippines-layout",
    src: "https://cdn.curator.io/published/ce07d728-f4cb-4e1d-92a5-86c889413ef5.js",
  },
  // Portugal
  pt: {
    id: "curator-feed-benelli-portugal-layout",
    src: "https://cdn.curator.io/published/cc695c24-1238-4df6-ad27-5b0b4ca5d38d.js",
  },
  // Thailand
  th: {
    id: "curator-feed-benelli-thailand-layout",
    src: "https://cdn.curator.io/published/b3d6ad02-4f1d-4786-a25e-84dfc46ec824.js",
  },
  // Ukraine
  ua: {
    id: "curator-feed-benelli-ukrania-layout",
    src: "https://cdn.curator.io/published/abbbd013-3a58-48ef-9efa-6bebfafe5ed6.js",
  },
  // United States
  us: {
    id: "curator-feed-benelli-united-states-layout",
    src: "https://cdn.curator.io/published/612e0517-1c36-4fbb-8e51-3862945e0791.js",
  },
  // Uruguay
  uy: {
    id: "curator-feed-benelli-uruguay-layout",
    src: "https://cdn.curator.io/published/a87d277b-3824-48dd-a8fa-cbdb292ed01b.js",
  },
  // India
  in: {
    id: "curator-feed-benelli-india-layout",
    src: "https://cdn.curator.io/published/f46a486d-833c-44e0-be3d-e7b358f627a7.js",
  },
  // Algeria
  dz: {
    id: "curator-feed-benelli-algeria-layout",
    src: "https://cdn.curator.io/published/3e08008d-686a-4899-9912-52eec5ddb418.js",
  },
  // Armenia
  am: {
    id: "curator-feed-benelli-armenia-layout",
    src: "https://cdn.curator.io/published/daf995f5-5906-492c-8c2b-ede2d01c706a.js",
  },
  // georgia
  ge: {
    id: "curator-feed-benelli-georgia-layout",
    src: "https://cdn.curator.io/published/f70b5594-81a3-4d8c-8f86-1f9dbc53f1da.js",
  },
  // Germany
  de: {
    id: "curator-feed-benelli-germany-layout",
    src: "https://cdn.curator.io/published/928e783d-0a6a-4d38-97b7-ed818f4d6d5f.js",
  },
  // Ghana
  gh: {
    id: "curator-feed-benelli-ghana-layout",
    src: "https://cdn.curator.io/published/fe79b7ee-4da3-4850-baba-ebb0d657f066.js",
  },
  // Indonesia
  id: {
    id: "curator-feed-benelli-indonesia-layout",
    src: "https://cdn.curator.io/published/0035b764-f686-46fe-a535-d9464b9e23cb.js",
  },
  // Israel
  il: {
    id: "curator-feed-benelli-israel-layout",
    src: "https://cdn.curator.io/published/edb05075-e266-4433-aaec-567d0ffcc46b.js",
  },
  // Japan
  jp: {
    id: "curator-feed-benelli-japan-layout",
    src: "https://cdn.curator.io/published/b6ccb31e-f0a0-4b6a-9b9b-f4d697422aef.js",
  },
  // Lebanon
  lb: {
    id: "curator-feed-benelli-lebanon-layout",
    src: "https://cdn.curator.io/published/9564663e-6917-4350-b136-e3ced41ae961.js",
  },
  // Qatar
  qa: {
    id: "curator-feed-benelli-qatar-layout",
    src: "https://cdn.curator.io/published/7fe18f85-884d-4d7c-a027-cae8b78acb2c.js",
  },
  // South Korea
  KR: {
    id: "curator-feed-benelli-south-korea-layout",
    src: "https://cdn.curator.io/published/5874e3c6-3fe6-4031-b37d-b6010ab74762.js",
  },
  // Turkey
  tr: {
    id: "curator-feed-benelli-turkey-layout",
    src: "https://cdn.curator.io/published/75c78ce1-cf26-4486-aa63-4a8bf583573e.js",
  },
  // United Arab Emirates
  ae: {
    id: "curator-feed-benelli-united-arab-emirates-layout",
    src: "https://cdn.curator.io/published/aef2c11c-df4a-477c-a09f-deae2e273b2a.js",
  },
};

const mainStore = useMainStore();

const curatorSettings = reactive(
  curatorFeeds[mainStore.country]
    ? curatorFeeds[mainStore.country]
    : curatorFeeds.default
);

const scripts = [];
if (process.browser && mainStore.currentSite.settings.curator) {
  scripts.push({
    // class: '_iub_cs_activate',
    // 'data-suppressedsrc': curatorFeeds[mainStore.country] ? curatorFeeds[mainStore.country].src : curatorFeeds.default.src
    // 'data-iub-purposes': 3,
    crossorigin: true,
    defer: true,
    // type: 'text/plain'
    src: curatorFeeds[mainStore.country]
      ? curatorFeeds[mainStore.country].src
      : curatorFeeds.default.src,
  });
}

useHead({
  script: scripts,
});
</script>

<style lang="postcss">
@media screen and (max-width: 768px) {
  .crt-widget-carousel {
    .crt-controls-outside {
      @apply text-center px-0 !important;

      button {
        @apply inline relative bottom-[-60px] !important;

        &.crt-panel-prev {
          @apply me-4 !important;
        }
      }
    }

    .crt-match-heights .crt-carousel-pane {
      border: 1px solid transparent !important;
    }
  }
}

.crt-feed-window {
  @apply !h-auto;
}

.crt-widget-waterfall {
  min-height: auto !important;
  padding-bottom: 0 !important;
}

.crt-panel-next svg,
.crt-panel-prev svg {
  width: 100% !important;
}

.crt-widget {
  @apply !bg-[#020202];
}
</style>
